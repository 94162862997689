import { getCodeRepoPackagesData } from "@/api/useInventory.selectors"

export interface Integration {
  name: string
  isEnabled: boolean
  isInstalled: boolean
}

export interface GitLabIntegration {
  groupName: string
  projectsCount: string
}

export type JiraMetadata = {
  integrationsStatus: Record<string, boolean>
}

export type IntegrationsListResponse = KosmosApi.Response<Integration[]>

export interface IntegrationCallBackParams extends Record<string, string> {
  vendor: string
}

export type GitHubInstallation = {
  installationId: number
  organizationName: string
  repoCount: number
  vulnerabilityReportsEnabled: boolean
}

export type GitHubConfiguration = {
  installations: GitHubInstallation[]
}

export type EcrInstallation = {
  accountId: string
  regionToRegistryUrl: any
  registriesCount: number
  repositories: string[]
}

export type EcrConfiguration = {
  accountRegistries: EcrInstallation[]
  installations?: undefined
}

export type EcrUpdateConfiguration = {
  params: EcrInstallation[]
  installations?: undefined
}

export type GilLabConfiguration = {
  installations: GitLabIntegration[]
}

// Union of all possible integration configurations payload
export type IntegrationConfiguration =
  | GitHubConfiguration
  | EcrConfiguration
  | EcrUpdateConfiguration
  | GilLabConfiguration
  | WebhookConfiguration

export type CodeRepoPackage = ReturnType<
  typeof getCodeRepoPackagesData
>["data"][number]

export type KodemCIExtra = {
  version: string
}

export type IntegrationExtraInfo = KodemCIExtra | undefined

export interface IntegrationDetails {
  integration: Integration
  configuration: IntegrationConfiguration
  extraInfo?: IntegrationExtraInfo
}

export type IntegrationDetailsResponse = KosmosApi.Response<
  IntegrationDetails,
  null
>

export type PreInstallDetails = {
  regions: string[]
  kodemAwsAccountId: string
}

export type PreInstallIntegrationResponse = KosmosApi.Response<
  PreInstallDetails,
  null
>

export type EcrUpdateResponse = {
  data: {
    updateResults: {
      accountId: string
      updateStatus: "success" | "failure"
      updateMessage: string
    }[]
  }
}

export type howToUse = {
  integrationSample: string
}

export type SectionInfo = {
  title: string
  noteTitle?: string
  description?: string
  prerequisites?: any[]
  howToUse?: howToUse
  instructionsLink?: string
}

export type SubmitButtonInfo = {
  description: string
  label: string
  link: string
  note?: string
  connect?: string
  disabled?: boolean
  icon?: SubmitIconType
}

export type SubmitIconType = "default" | "externalLink"

export type ConnectPageInfo = {
  sections?: SectionInfo[]
  submitButton?: SubmitButtonInfo
}

export type IntegrationStaticInfo = {
  name: string
  displayName: string
  description: string
  tags: string[]
  connectPage?: ConnectPageInfo
  isBeta?: boolean
  onlyLogo?: boolean
}

export const KodemCIPublicImageName =
  "us-docker.pkg.dev/kodem-public/kodem-ci/kodemci"

interface WebhookInstallation {
  id: string
  name: string
  url: string
  description?: string
  secret?: string
}
export interface WebhookConfiguration {
  installations: WebhookInstallation[]
}

export type TestWebhookResponse = KosmosApi.Response<{
  success: boolean
  message?: string
}>
