import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ThreatCard } from "../ThreatCard"

import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (attr: { t: TFunction }) => {
  return [
    {
      render: ThreatCard(attr),
      ...getDefaultColumnParams("name")
    }
  ]
}
