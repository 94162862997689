import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { LogoIntegration } from "../LogoIntegration"

import styles from "./HeaderCard.module.scss"

import { FeatureStatusTag } from "@/molecules/FeatureStatusTag"
import type { HeaderCardProps } from "."

export const HeaderCard = (props: HeaderCardProps) => {
  const { t } = useTranslation()
  const { isEnabled, isBeta } = props

  return (
    <>
      <LogoIntegration {...props} />
      {!isEnabled && (
        <div className={styles.comingSoon}>
          <Text>{t("general.coming_soon")}</Text>
        </div>
      )}
      {isEnabled && isBeta && (
        <div className={styles.comingSoon}>
          <FeatureStatusTag type="beta" />
        </div>
      )}
    </>
  )
}
