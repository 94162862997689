import cn from "classnames"

import { Space, Tag, Text } from "@/atoms"
import { getColorBySeverity } from "@/domain/vulnerability"
import { getRelativeTimeFromNow } from "@/helpers/date.helpers"
import { ClockCircleFilled, Injection } from "@/icons"
import { SeverityTag } from "@/molecules/SeverityTag"
import { Buttons } from "../Buttons"

import styles from "./ThreatCard.module.scss"

import { Incident } from "@/api/useIncidents.types"
import type { TFunction } from "@/hooks/useTranslation"
import { getSubTitleValue } from "../helpers"

// TODO: Implement buttons functionality
export const ThreatCard =
  ({ t }: { t: TFunction }) =>
  (_: unknown, record: Incident) => {
    const { threatDetails, where, action, detectedAt } = record
    const { attackType, severity } = threatDetails
    const { environment, namespace, podName } = where

    return (
      <div className={styles.container}>
        <div className={cn(styles.columnContainer, styles.asideContainer)}>
          <div className={styles.titleContainer}>
            <Injection
              className={styles.icon}
              style={{ backgroundColor: getColorBySeverity(severity) }}
            />

            <div className={styles.columnContainer}>
              <span className={styles.title}>{attackType}</span>
              <Text type="secondary" className={styles.subTitle}>
                {`${environment}${getSubTitleValue(namespace)}${getSubTitleValue("")}${getSubTitleValue(podName)}`}
              </Text>
            </div>
          </div>
          <Space split="|">
            <SeverityTag severity={severity} t={t} />
            <Text type="secondary" className={styles.tagContainer}>
              {t("triage.incidents.actionsTaken")}
              <Tag className={styles.threatDetectedTagThreatPage}>{action}</Tag>
            </Text>
            <Text type="secondary">
              <ClockCircleFilled /> {getRelativeTimeFromNow(detectedAt)}
            </Text>
          </Space>
        </div>
        <Buttons record={{ ...record, key: record.id }} />
      </div>
    )
  }
