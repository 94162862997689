import {
  Amazon,
  Asana,
  AzureRepos,
  Bitbucket,
  Checkmarx,
  CircleCI,
  DockerHub,
  GCR,
  GithubActions,
  GithubOutlined,
  Gitlab,
  IntegrationInstallationStatus,
  Jenkins,
  Jfrog,
  JiraSoftwareLogo,
  KodemCI,
  Mend,
  Orca,
  PrismaCloud,
  Qualys,
  ServiceNow,
  Slack,
  Snyk,
  Splunk,
  VSCode,
  Webhook,
  Wiz
} from "@/icons"
import cn from "classnames"

import styles from "./LogoIntegration.module.scss"

import type { LogoIntegrationProps } from "."

export const LOGO_INTEGRATIONS_BY_NAME = {
  github: GithubOutlined,
  jira: JiraSoftwareLogo,
  bitbucket: Bitbucket,
  ecr: Amazon,
  slack: Slack,
  asana: Asana,
  wiz: Wiz,
  azure_repos: AzureRepos,
  gitlab: Gitlab,
  service_now: ServiceNow,
  splunk: Splunk,
  docker_hub: DockerHub,
  gcr: GCR,
  qualys: Qualys,
  serviceNow: ServiceNow,
  orca: Orca,
  prisma_cloud: PrismaCloud,
  snyk: Snyk,
  jfrog_artifcatory: Jfrog,
  checkmarx: Checkmarx,
  mend: Mend,
  kodem_ci: KodemCI,
  jenkins: Jenkins,
  circle_ci: CircleCI,
  github_actions: GithubActions,
  webhook: Webhook,
  vscode: VSCode
} as Record<string, React.FC>

export const LogoIntegration = ({
  className,
  name,
  isInstalled,
  onlyLogo
}: LogoIntegrationProps) => {
  const Logo = LOGO_INTEGRATIONS_BY_NAME[name]

  if (!Logo) return null

  return (
    <div className={cn(styles.logo, className)}>
      <Logo />
      {!onlyLogo && (
        <IntegrationInstallationStatus
          isInstalled={isInstalled}
          classes={{ container: styles.link }}
        />
      )}
    </div>
  )
}
