import { Checkbox, Divider, Flex, Form, Input, Space, Text } from "@/atoms"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ImageAutoComplete } from "./ImagesAutoComplete"
import { MatchBadge } from "./MatchBadge"

import styles from "./MonorepoConfigurationsForm.module.scss"

import type { MonorepoConfigurationsFormValues } from "./MonorepoConfigurationsForm.types"

const HelperLabel = (props: { label: string; help: string }) => {
  return (
    <Space>
      {props.label}
      <InformationPopover.Popover
        content={
          <InformationPopover.CommonTemplate>
            {props.help}
          </InformationPopover.CommonTemplate>
        }
      />
    </Space>
  )
}
export function MonorepoConfigurationsForm(props: {
  /**
   * Name of the project
   */
  // TODO: we are not allowing user to change it ATM. Once we do should go into values
  projectName: string
  /**
   * Values
   */
  values: MonorepoConfigurationsFormValues
  /**
   * Called when change was made to any field
   */

  isRootSelected: boolean

  onChange: (values: MonorepoConfigurationsFormValues) => void
}) {
  const { t } = useTranslation("translation", {
    keyPrefix: "inventory.codeRepositoriesTab.drawer.config"
  })
  const { values } = props
  const {
    imageRepositories: selectedImageRepositories = [],
    splitPath = false,
    exclude = false
  } = values
  const correlations = useMemo(() => {
    return selectedImageRepositories.reduce(
      (acc, value) => {
        acc[value.imageRepository] = value.correlationType
        return acc
      },
      {} as Record<string, "auto" | "manual">
    )
  }, [values])

  const handleChange = <
    K extends keyof MonorepoConfigurationsFormValues,
    V = MonorepoConfigurationsFormValues[K]
  >(
    field: K,
    newValue: V
  ) => {
    const values = {
      ...props.values,
      [field]: newValue
    }
    props.onChange(values)
  }

  return (
    <Flex vertical gap={16} className={styles.container}>
      <Heading level={5} title={t("projectConfigurations")} />
      <Form layout="vertical">
        {!props.isRootSelected && (
          <>
            <Flex vertical gap={16}>
              <Heading level={6} title={t("splitTitle")} />
              <Form.Item>
                <Checkbox
                  disabled={props.isRootSelected}
                  checked={splitPath}
                  onChange={(v) => {
                    handleChange("splitPath", v.target.checked)
                  }}
                >
                  {t("splitLabel")}
                </Checkbox>
              </Form.Item>
              {splitPath && (
                <Form.Item
                  label={
                    <HelperLabel
                      label={t("projectNameLabel")}
                      help={t("projectNameHelp")}
                    />
                  }
                >
                  <Input disabled value={props.projectName} />
                </Form.Item>
              )}
            </Flex>
            <Divider />
          </>
        )}
        <Flex vertical gap={8}>
          <Heading level={6} title={t("imageCorrelationTitle")} />
          <div>
            <Form.Item
              label={
                <HelperLabel
                  label={t("imageCorrelationsLabel")}
                  help={t("imageCorrelationsHelp")}
                />
              }
              help={
                <Text type="secondary">
                  <Space>
                    <span>{t("correlationDoneByLabel")}</span>
                    <span className={styles.badgeWrapper}>
                      <MatchBadge matchType="auto" />
                      {t("correlationAuto")}
                    </span>
                    <span className={styles.badgeWrapper}>
                      <MatchBadge matchType="manual" />
                      {t("correlationManual")}
                    </span>
                  </Space>
                </Text>
              }
            >
              <ImageAutoComplete
                disabled={!splitPath && !props.isRootSelected}
                selectedImageRepositories={selectedImageRepositories}
                value={selectedImageRepositories.map((v) => ({
                  value: v.imageRepository,
                  label: v.imageRepository,
                  ...v
                }))}
                onChange={(values) => {
                  if (!values) {
                    handleChange("imageRepositories", [])
                    return
                  }
                  handleChange(
                    "imageRepositories",
                    values.map((value) => ({
                      imageRepository: value,
                      correlationType: correlations[value] || "manual"
                    }))
                  )
                }}
              />
            </Form.Item>
          </div>
        </Flex>
        <Divider />
        <Flex vertical gap={16}>
          <Heading level={6} title={"Scan Exclusion"} />
          <Form.Item>
            <Checkbox
              disabled={!splitPath && !props.isRootSelected}
              checked={exclude}
              onChange={(v) => {
                handleChange("exclude", v.target.checked)
              }}
            >
              {props.isRootSelected ? t("excludeRepoLabel") : t("excludeLabel")}
            </Checkbox>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  )
}
