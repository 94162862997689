import {
  useGetRepositoryConfig,
  useGetRepositoryModules,
  useResetRepositoryConfig,
  useUpdateRepositoryConfig
} from "@/api/useInventory"
import { App } from "@/atoms"
import { isEmpty } from "@/helpers/utils"
import { ROOT_KEY } from "@/molecules/ModulesTree/ModulesTree.types"
import { MonorepoConfigurations } from "@/organisms/MonorepoConfigurations/MonorepoConfigurations"
import { Configs } from "@/organisms/MonorepoConfigurations/MonorepoConfigurations.types"
import { Loader } from "@/pages/SettingsPage/components/molecules/Loader"
import { useTranslation } from "react-i18next"
import { useBranchEntity } from "../../applications/inventory"
import { CodeRepoConfigTabComponent } from "./ConfigTab.types"

import { RepositoryCorrelation } from "@/api/useInventory.types"
import styles from "./ConfigTab.module.scss"

const findNestedPaths = (
  paths: string[]
): { parent: string; nested: string }[] => {
  const sortedPaths = [...paths].sort()
  const nestedPairs: { parent: string; nested: string }[] = []
  for (let i = 0; i < sortedPaths.length - 1; i++) {
    const current = sortedPaths[i]
    const next = sortedPaths[i + 1]

    if (next.startsWith(current + "/")) {
      nestedPairs.push({ parent: current, nested: next })
    }
  }

  return nestedPairs
}

const haveDuplicateImages = (configs: Configs) => {
  const images = Object.values(configs).flatMap((config) =>
    config.imageRepositories.map((i) => i.imageRepository)
  )
  return new Set(images).size !== images.length
}

const defaultEmptyConfig = (correlations: RepositoryCorrelation[]) => {
  return {
    "": {
      splitPath: false,
      exclude: false,
      imageRepositories: correlations
        .filter((c) => c.path === "")
        .map((c) => ({
          imageRepository: c.imageRepository,
          correlationType: c.source
        }))
    }
  } as Configs
}

export const CodeRepoConfigTab: CodeRepoConfigTabComponent = (props) => {
  const { message } = App.useApp()
  // we use related images to overcome coupling between project configs and custom correlations
  const [context, { repositoryName, sourceControl }] = useBranchEntity(
    props.record?.id
  )

  const { t } = useTranslation()
  const modules = useGetRepositoryModules(repositoryName)
  const updateConfig = useUpdateRepositoryConfig(repositoryName)
  const repositoryConfig = useGetRepositoryConfig(repositoryName)
  const resetConfig = useResetRepositoryConfig(repositoryName)

  if (modules.isLoading || context.isLoading) return <Loader />

  if (modules.isError || repositoryConfig.isError)
    return (
      <div>
        {t("inventory.codeRepositoriesTab.drawer.config.errors.failedLoading")}
      </div>
    )
  if (!modules.response || modules.response.length === 0) {
    return (
      <div>{t("inventory.codeRepositoriesTab.drawer.config.noModules")}</div>
    )
  }
  const relatedImages = repositoryConfig.response?.correlations || []
  const record = context.response?.data?.[0]
  const isEmptyConfig = isEmpty(repositoryConfig.response?.config)

  return (
    <div className={styles.container}>
      <MonorepoConfigurations
        record={record}
        configLoading={repositoryConfig.isLoading}
        repoName={repositoryName}
        scm={sourceControl}
        modules={modules.response}
        isEmptyConfig={isEmptyConfig}
        configs={
          isEmptyConfig
            ? defaultEmptyConfig(relatedImages)
            : repositoryConfig.response!.config
        }
        loading={updateConfig.isPending}
        onReset={async () => {
          try {
            await resetConfig.mutateAsync(undefined)
            message.success(
              t("inventory.codeRepositoriesTab.drawer.config.resetSuccess")
            )
            repositoryConfig.refetch()
          } catch {
            message.error(
              t(
                "inventory.codeRepositoriesTab.drawer.config.errors.resetFailed"
              )
            )
          }
        }}
        onSave={async (config) => {
          const nestedPaths = findNestedPaths(Object.keys(config))
          const hasNestedPaths = nestedPaths.length > 0
          if (hasNestedPaths) {
            message.error(
              t(
                "inventory.codeRepositoriesTab.drawer.config.errors.nestedPaths",
                {
                  parent: nestedPaths[0].parent,
                  child: nestedPaths[0].nested
                }
              )
            )
            return
          }

          if (haveDuplicateImages(config)) {
            message.error(
              t(
                "inventory.codeRepositoriesTab.drawer.config.errors.duplicateImages"
              )
            )
            return
          }

          try {
            await updateConfig.mutateAsync({
              projects: Object.keys(config).map((path) => ({
                name: path,
                paths: [path === ROOT_KEY ? "" : "/" + path],
                ignore: config[path].exclude,
                imageRepositories: config[path].imageRepositories.map(
                  (i) => i.imageRepository
                ),
                split: config[path].splitPath
              }))
            })

            message.success(
              t("inventory.codeRepositoriesTab.drawer.config.saveSuccess")
            )
            repositoryConfig.refetch()
          } catch {
            message.error(
              t("inventory.codeRepositoriesTab.drawer.config.errors.saveFailed")
            )
          }
        }}
      />
    </div>
  )
}
