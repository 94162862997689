import {
  EcrConfiguration,
  EcrUpdateConfiguration,
  EcrUpdateResponse,
  GilLabConfiguration,
  GitHubConfiguration,
  IntegrationConfiguration,
  WebhookConfiguration
} from "@/api/useIntegrations.types"
import { Breadcrumb, Form, Link, Skeleton, Tabs } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { useActiveKeySearchParams } from "@/hooks/useActiveKeySearchParams"
import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { useState } from "react"
import { Navigate } from "react-router"
import { HeaderItem } from "../../molecules/HeaderItem"
import { ItemIntegrationSkeleton } from "../../molecules/ItemIntegrationSkeleton"
import { IntegrationDetailsFooter } from "../../organisms/IntegrationDetailsFooter"
import { EcrConfigurationForm } from "../../organisms/ItemConfiguration/EcrConfigurationForm"
import { GitHubConfigurationForm } from "../../organisms/ItemConfiguration/GitHubConfigurationForm"
import { ItemIntegration } from "../../organisms/ItemIntegration"
import { WebhookConfigurationTab } from "../../organisms/WebhookConfigurationTab"
import { ConfigureTabGitLabIntegration } from "../GitLabIntegrationPage/components/ConfigureTabGitLabIntegration"
import {
  useDeleteIntegration,
  useUpdateIntegration
} from "./hooks/useItemIntegration"
import styles from "./ItemIntegrationPage.module.scss"
import { ItemIntegrationPageComponentProps } from "./ItemIntegrationPage.types"

const formatPayload = (
  vendor: string,
  configuration: IntegrationConfiguration
):
  | GitHubConfiguration
  | EcrConfiguration
  | EcrUpdateConfiguration
  | GilLabConfiguration
  | WebhookConfiguration => {
  switch (vendor) {
    case "github":
      return {
        installations: (
          configuration as GitHubConfiguration
        ).installations.reduce(
          (acc, item) => {
            acc[item.installationId] = item
            return acc
          },
          {} as GitHubConfiguration["installations"]
        )
      }
    case "ecr":
      return {
        params: (configuration as any).accounts.map((account: any) => ({
          accountId: account.accountId,
          regions: account.regions,
          repositories: account.repositories
        }))
      }
    default:
      return configuration
  }
}

export const ItemIntegrationComponent = ({
  name,
  integration,
  configuration,
  extraInfo,
  isLoading,
  refetchIntegration,
  messageApi
}: ItemIntegrationPageComponentProps) => {
  const { t } = useTranslation()
  const { updateIntegration } = useUpdateIntegration(name!)
  const { deleteIntegration } = useDeleteIntegration(name!)
  const [form] = Form.useForm()
  const tabKeys = TAB_KEYS_BY_PAGE.INTEGRATION
  const [activeTabKey, setActiveTabKey] = useActiveKeySearchParams(tabKeys)
  const [isFormDirty, setFormDirty] = useState(false)

  if (!isLoading && !integration) {
    return <Navigate to="../" replace />
  }

  const { displayName = "" } = integration || {}

  const showErrorToast = (status: string, data?: { detail: string }) => {
    const errContent: { [key: string]: string | undefined; default: string } = {
      403: data?.detail,
      default: t("integrations.configuration.failedSavingConfiguration")
    }
    const content = errContent[status] || errContent.default

    messageApi.open({ content, type: "error", duration: 3 })
  }

  const handleApply = async (newConfiguration: IntegrationConfiguration) => {
    try {
      const res = await updateIntegration(
        formatPayload(name!, newConfiguration)
      )
      const { updateResults } = (res as EcrUpdateResponse).data
      refetchIntegration()

      updateResults.forEach((result) => {
        if (result.updateStatus === "success") {
          messageApi.open({
            type: "success",
            content: t("integrations.configuration.updateSuccess", {
              accountId: result.accountId
            }),
            duration: 3
          })
        } else {
          messageApi.open({
            type: "error",
            content: t("integrations.configuration.updateFailure", {
              accountId: result.accountId
            }),
            duration: 3
          })
        }
      })
    } catch (error: any) {
      const { status, data } = error || {}
      showErrorToast(status, data)
    }
  }

  const handleRemove = async (account_id: string) => {
    try {
      await deleteIntegration(account_id)
      refetchIntegration()
      messageApi.open({
        type: "success",
        content: t("integrations.configuration.configurationSaved"),
        duration: 3
      })
    } catch (error: any) {
      const { status, data } = error || {}
      showErrorToast(status, data)
    }
  }

  const itemConfiguration: Record<
    string,
    { element: React.ReactElement; disabled: boolean }
  > = {
    ecr: {
      element: (
        <EcrConfigurationForm
          configuration={configuration as EcrConfiguration}
          onApply={handleApply}
          onRemove={handleRemove}
          form={form}
          setFormDirty={setFormDirty}
        />
      ),
      disabled: false
    },
    github: {
      element: (
        <Form>
          <GitHubConfigurationForm
            configuration={configuration as GitHubConfiguration}
            onApply={handleApply}
            form={form}
            setFormDirty={setFormDirty}
          />
        </Form>
      ),
      disabled: !integration?.isInstalled
    },
    gitlab: {
      element: (
        <ConfigureTabGitLabIntegration
          messageApi={messageApi}
          configuration={configuration as GilLabConfiguration}
          refetchIntegration={refetchIntegration}
        />
      ),
      disabled: !integration?.isInstalled
    },
    webhook: {
      element: (
        <WebhookConfigurationTab
          configuration={configuration as WebhookConfiguration}
          messageApi={messageApi}
        />
      ),
      disabled: !integration?.isInstalled
    }
  }

  const showFooter =
    activeTabKey === "configuration" &&
    integration?.name !== INTEGRATION_VENDORS.webhook

  return (
    <PageWithBreadcrumbTemplate
      classes={{ main: styles.main }}
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/integrations">
                      {t("navigation.integrations")}
                    </Link>
                  )
                },
                {
                  title: displayName || <Skeleton.Input active size="small" />
                }
              ]}
            />
          }
        />
      }
      footer={
        showFooter && (
          <IntegrationDetailsFooter
            isFormDirty={isFormDirty}
            form={form}
            setFormDirty={setFormDirty}
            resetConfiguration={() => form.setFieldsValue(configuration)}
          />
        )
      }
    >
      {isLoading ? (
        <div className={`${styles.wrap} ${styles.loading}`}>
          <Skeleton.Node>
            <div />
          </Skeleton.Node>
          <ItemIntegrationSkeleton />
        </div>
      ) : (
        <>
          <HeaderItem {...integration!} />
          <Tabs
            activeKey={activeTabKey}
            items={[
              {
                label: t("integrations.connectTab"),
                key: tabKeys[0],
                children: (
                  <div className={styles.wrap}>
                    <ItemIntegration extraInfo={extraInfo} {...integration} />
                  </div>
                )
              },
              itemConfiguration[integration!.name] && {
                label: t("integrations.configuration.configurationTab"),
                key: tabKeys[1],
                disabled: itemConfiguration[integration!.name].disabled,
                children: (
                  <div className={styles.wrap}>
                    {itemConfiguration[integration!.name].element}
                  </div>
                )
              }
            ]}
            onChange={(key) => {
              setActiveTabKey(key)
            }}
          />
        </>
      )}
    </PageWithBreadcrumbTemplate>
  )
}
