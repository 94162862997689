import { Button, Link, Text, Title } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { ConnectPlug, ReadOutlined } from "@/icons"

import { Trans, useTranslation } from "@/hooks/useTranslation"
import { InformationCard } from "@/molecules/InformationCard"
import { Protected } from "@/molecules/Protected"
import { HowToUseIntegration } from "../HowToUseIntegration"
import styles from "./ItemIntegration.module.scss"
import { LinkIcon } from "./LinkIcon"

export const ItemIntegration = ({ name, connectPage, extraInfo }: any) => {
  const { t: tGeneral } = useTranslation("translation", {
    keyPrefix: "general"
  })
  const { logger, EVENTS } = useLogger()
  const { submitButton, sections = [] } = connectPage || {}
  const [firstSection, secondSection, howToUse] = sections
  const {
    label: labelSubmitButton,
    description: descriptionSubmitButton,
    link: linkSubmitButton,
    note: noteSubmitButton,
    connect: connectSubmitButton,
    disabled: disabledSubmitButton,
    icon: iconSubmitButtonKey = "default"
  } = submitButton || {}

  const showConnectButton = connectSubmitButton

  return (
    <>
      {firstSection && (
        <div className={styles.firstSection}>
          <Title level={5} className={styles.title}>
            {firstSection.title}
          </Title>
          <Text>
            <Trans>{firstSection.description}</Trans>
          </Text>
        </div>
      )}
      {secondSection && (
        <div>
          <Title level={5} className={styles.title}>
            {secondSection.title}
            {secondSection.instructionsLink && (
              <Button
                icon={<ReadOutlined />}
                type="link"
                href={secondSection.instructionsLink}
                target="_blank"
              >
                {tGeneral("readFullInstructions")}
              </Button>
            )}
          </Title>
          <InformationCard
            infoMessage={secondSection.noteTitle || "Prerequisites"}
          >
            {secondSection.description && (
              <span className={styles.description}>
                {secondSection.description}
              </span>
            )}
            <ul className={styles.listPrerequisites}>
              {secondSection.prerequisites.map((item: any, index: number) => {
                if (typeof item === "string") {
                  return (
                    <li key={index}>
                      <Text>{secondSection.prerequisites[index]}</Text>
                    </li>
                  )
                }

                return Object.keys(item).map((key: any) => {
                  return (
                    <li key={key}>
                      <Text>{key}</Text>
                      <ul>
                        {item[key].map((item: any) => (
                          <li key={item}>
                            <Text>{item}</Text>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )
                })
              })}
            </ul>
          </InformationCard>
        </div>
      )}
      <div className={styles.submitButtonSection}>
        {descriptionSubmitButton && (
          <Title level={5}>{descriptionSubmitButton}</Title>
        )}
        {noteSubmitButton && (
          <Text className={styles.note} type="secondary">
            {noteSubmitButton}
          </Text>
        )}
        {linkSubmitButton && !showConnectButton && (
          <Button
            disabled={disabledSubmitButton}
            icon={<LinkIcon type={iconSubmitButtonKey} />}
            type="primary"
            href={linkSubmitButton}
            target="_blank"
            onClick={() => {
              logger.info(
                EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.OPENED_INSTRUCTION,
                {
                  name
                }
              )
            }}
          >
            {labelSubmitButton}
          </Button>
        )}
        {connectSubmitButton && (
          <Link to={connectSubmitButton}>
            <Protected
              permission={{ resource: "integrations", action: "write" }}
              shouldDisable
            >
              <Button
                icon={<ConnectPlug />}
                type="primary"
                onClick={() => {
                  logger.info(
                    EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.OPENED_CONNECT,
                    {
                      name
                    }
                  )
                }}
              >
                {labelSubmitButton}
              </Button>
            </Protected>
          </Link>
        )}
      </div>
      {howToUse && howToUse.integrationSample !== "TBD" && (
        <HowToUseIntegration name={name} {...howToUse} extraInfo={extraInfo} />
      )}
    </>
  )
}
