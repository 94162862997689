import { useNavigate, useParams } from "react-router-dom"

import { Button, Dropdown } from "@/atoms"
import { preventEventBubbling } from "@/helpers/utils"
import { CloseCircleOutlined, MailOutlined, StopOutlined } from "@/icons"

import styles from "./Buttons.module.scss"

// TODO: Enable buttons
export const Buttons = ({
  record,
  onClose
}: {
  record: { key: string }
  onClose?: () => void
}) => {
  const notifyItems = [
    { key: "1", label: "Send Email" },
    { key: "2", label: "Send to Splunk" }
  ]
  const blockItems = [
    { key: "1", label: "Kill Process" },
    { key: "2", label: "Kill Container" },
    { key: "3", label: "Kill Pod" }
  ]
  const dismissItems = [
    { key: "dismiss", label: "Dismiss the Incident" },
    { key: "2", label: "Dismiss and Remove This Flow in Policy" }
  ]

  const navigate = useNavigate()
  const { incident } = useParams()

  return (
    <div className={styles.container} onClick={preventEventBubbling}>
      <Dropdown
        menu={{
          items: dismissItems,
          onClick: ({ key }) => {
            if (key === "dismiss") {
              let url = "/triage/incidents"

              if (incident) {
                const data = JSON.parse(atob(incident)).filter(
                  ({ key }: { key: number }) => `${key}` !== `${record.key}`
                )

                if (data.length) {
                  url = `/triage/incidents/${btoa(JSON.stringify(data))}`
                }
              }

              onClose?.()
              navigate(url)
            }
          }
        }}
        disabled
      >
        <Button
          disabled
          type="secondary"
          size="small"
          icon={<CloseCircleOutlined />}
        >
          Dismiss
        </Button>
      </Dropdown>
      <Dropdown menu={{ items: blockItems }} disabled>
        <Button disabled type="secondary" size="small" icon={<StopOutlined />}>
          Block
        </Button>
      </Dropdown>
      <Dropdown menu={{ items: notifyItems }} disabled>
        <Button disabled type="secondary" size="small" icon={<MailOutlined />}>
          Notify
        </Button>
      </Dropdown>
    </div>
  )
}
