import type {
  KeyRuntimeEvidence,
  RuntimeEvidence,
  RuntimeEvidenceType
} from "."

export const getKeyEvidenceList = (
  keyEvidence: KeyRuntimeEvidence,
  language: Language
) => {
  let keyEvidenceList: string[] = []
  let translationKey = undefined

  if (!!keyEvidence?.type) {
    const type = keyEvidence.type

    if (
      isFromVulnerableFunctionEvidence(type) &&
      keyEvidence?.functions?.length > 0
    ) {
      translationKey =
        type === "functions_in_runtime"
          ? "runtimeVulnerableFunctionKeyEvidence"
          : "notInRuntimeVulnerableFunctionKeyEvidence"
      keyEvidenceList = keyEvidence.functions.map((functionName) =>
        getFunctionName(functionName)
      )
    } else if (isFromClassEvidence(type) && keyEvidence?.classes?.length > 0) {
      if (isJvmLanguage(language)) {
        translationKey =
          type === "class_in_runtime"
            ? "runtimeVulnerableClassKeyEvidence"
            : "notInRuntimeVulnerableClassKeyEvidence"
      } else {
        translationKey =
          type === "class_in_runtime"
            ? "runtimeVulnerableFileKeyEvidence"
            : "notInRuntimeVulnerableFileKeyEvidence"
      }

      keyEvidenceList = keyEvidence.classes
    } else if (keyEvidence?.functions?.length > 0) {
      translationKey = "runtimeFunctionKeyEvidence"
      keyEvidenceList = keyEvidence.functions.map((functionName) =>
        getFunctionName(functionName)
      )
    } else if (keyEvidence?.classes?.length > 0) {
      translationKey = "runtimeClassKeyEvidence"
      keyEvidenceList = keyEvidence.classes
    } else if (keyEvidence?.files?.length > 0) {
      translationKey = "runtimeFileKeyEvidence"
      keyEvidenceList = keyEvidence.files
    } else if (keyEvidence?.processes?.length > 0) {
      translationKey = "runtimeProcessKeyEvidence"
      keyEvidenceList = keyEvidence.processes
    }
  }

  return { translationKey, keyEvidenceList }
}

export const isFromVulnerableFunctionEvidence = (type: RuntimeEvidenceType) => {
  return type === "functions_in_runtime" || type === "functions_not_in_runtime"
}

export const isFromClassEvidence = (type: RuntimeEvidenceType) => {
  return type === "class_in_runtime" || type === "all_classes_not_in_runtime"
}

export const isFromPackageEvidence = (type: RuntimeEvidenceType) => {
  return (
    type === "package_in_runtime" ||
    type === "package_not_in_runtime" ||
    type === "non_vulnerable_functions_in_runtime" ||
    type === "package_loaded" ||
    type === "undetermined"
  )
}

export const isJvmLanguage = (language: Language) => {
  return language === "java" || language === "scala" // TODO: add Kotlin
}

export const getFunctionName = (name: string) => name.replace("::", ": ")

export const getRuntimeEvidenceJson = (
  runtimeEvidence: RuntimeEvidence[],
  isIssue: boolean = true
) => {
  const valuesToSkip = [null, undefined, "", 0]
  const functionKeys = ["functionsInRuntime", "vulnerableFunctionsNotInRuntime"]
  const functionObjectsKeys = [
    "vulnerableFunctionsInRuntime",
    "vulnerableFunctionsNotRuntime"
  ]
  const priorityKeys: { [key: string]: string } = {
    version: "versions",
    vulnerableFunctionsNotInRuntime: "vulnerableFunctionsNotRuntime"
  }
  const isEmpty = (v: any) =>
    valuesToSkip.some((valueToSkip) => v === valueToSkip) ||
    (Array.isArray(v) && v.length == 0) ||
    (typeof v === "object" && Object.keys(v).length == 0)

  const formattedRuntimeEvidence = runtimeEvidence.map((evidence: any) =>
    orderedKeys.reduce((acc: { [key: string]: any }, key) => {
      if (!isEmpty(evidence[key])) {
        if (isIssue && key === "inRuntime") {
          acc.issueInRuntime = evidence.inRuntime
        } else if (Array.isArray(evidence[key]) && functionKeys.includes(key)) {
          acc[key] = evidence[key].map((functionName) =>
            getFunctionName(functionName)
          )
        } else if (functionObjectsKeys.includes(key)) {
          acc[key] = Object.entries(evidence[key]).reduce(
            (funcObjAcc: { [key: string]: any }, [functionName, cves]) => {
              funcObjAcc[getFunctionName(functionName)] = cves
              return funcObjAcc
            },
            {}
          )
        } else if (
          !priorityKeys.hasOwnProperty(key) ||
          isEmpty(evidence?.[priorityKeys[key]])
        ) {
          acc[key] = evidence[key]
        }
      }

      return acc
    }, {})
  )

  return JSON.stringify(formattedRuntimeEvidence, null, 4)
}

const orderedKeys = [
  "environment",
  "namespace",
  "application",
  "image",
  "machine",
  "package",
  "version",
  "versions",
  "language",
  "inRuntime",
  "issueInRuntime",
  "lastObserved",
  "firstObserved",
  "functionsInRuntime",
  "vulnerableFunctionsInRuntime",
  "vulnerableFunctionsNotRuntime",
  "vulnerableFunctionsNotInRuntime",
  "loadedClasses",
  "loadedVulnerableClasses",
  "notLoadedVulnerableClasses",
  "loadedFiles",
  "loadedVulnerableFiles",
  "notLoadedVulnerableFiles",
  "executedProcesses"
]
