import { Role } from "."

export interface ScopeMetadata {
  id: string
  name: string
}

export interface Permissions {
  areAllScopesPermitted: boolean
  specificScopes: ScopeMetadata[]
}

export interface UserEntity {
  id: string
  email: string
  role?: Role
  firstName?: string
  lastName?: string
  jobDescription?: string
  lastLogin?: string
  status?: Status
  company?: string
  key: string
  initials?: string
  colorNumber?: number
  permissions?: Permissions
}

export interface User extends Omit<UserEntity, "key"> {
  initials: string
  company: string
  authorized: boolean
  featureFlags: Features.Flags
  photoURL?: string
  userNeedVerificationCodeMultiFactor?: boolean
  userNeedVerifyPhoneNumberMultiFactor?: boolean
  currentScopeId?: string | null
}

export type Status = "ACCEPTED" | "INVITED" | "DECLINED"

export const getColorNumberForUserAvatar = (email: string) => {
  return (email.length % 6) + 1
}

export const isKnownUserRole = (user: User) => !!user.role

export const isUserAdmin = (user: User) => user.role === "ADMIN"

export const isAuthorizedUser = (user: User) => !!user.authorized

export const createUser = (values: Partial<User> = {}): User => ({
  id: "",
  initials: "",
  email: "",
  company: "",
  authorized: false,
  featureFlags: {
    dataReadiness: false,
    internetFacingIntegration: undefined,
    kortexInstalled: undefined,
    scmInstalled: undefined,
    showOnboarding: undefined,
    sastScanFeature: undefined,
    isWelcomeDismissed: undefined,
    vmInstancesEnabled: undefined,
    roleEnforcement: undefined,
    repoConfigEnabled: undefined,
    ciIntegrated: undefined,
    sastPoliciesEnabled: undefined
  },
  ...values
})

export const getInitials = (fullNameOrEmail: string) => {
  try {
    return fullNameOrEmail
      .trim()
      .split("@")[0]
      .split(/[ .]+/)
      .reduce((acc, curr, index, arr) => {
        const isFirstName = index === 0
        const isLastName = index === arr.length - 1

        if (isFirstName || isLastName) {
          return `${acc}${curr.charAt(0).toUpperCase()}`
        }

        return acc
      }, "")
  } catch {
    return ""
  }
}

export const isKortexInstalled = (user: User) =>
  !!user?.featureFlags?.kortexInstalled

export const isSensorInstalled = (user: User) =>
  !!user?.featureFlags?.scmInstalled

export const noScopeSelected = (user: User) => user.currentScopeId === null
