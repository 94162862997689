import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"
import { VmDeployCommand } from "@/pages/DeploymentsPage/components/organisms/VmDeployCommand"

import type { Query } from "@/pages/DeploymentsPage/components/molecules/DownloadFileButton"

export const VmTab = (props: CommonDeployTabProps) => {
  const { noError, version } = props
  const packageSpecType = "vm"
  const files = [
    {
      name: `kortex-${version}-linux-arm64.tgz`,
      query: { packageSpecType, packageSpecArch: "arm64" } as Query
    },
    {
      name: `kortex-${version}-linux-amd64.tgz`,
      query: { packageSpecType, packageSpecArch: "amd64" } as Query
    }
  ]
  const { t } = useTranslation()

  return (
    <DeployTab
      steps={[
        {
          title: (
            <DownloadStep
              noError={noError}
              files={files}
              title={t("deployments.vm.deploymentStep")}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab prefix={t("general.deploy")}>
              {`- ${t("deployments.vm.commandStep")}`}
            </TitleStepDeployTab>
          ),
          description: <VmDeployCommand {...props} />,
          status: "process"
        }
      ]}
      message={
        <span>
          <Text>
            {t("deployments.vm.alert.text1")}
            <br />
            {t("deployments.vm.alert.text2")}
          </Text>
          <br />
          <b>{t("deployments.vm.alert.note")}</b>
        </span>
      }
    />
  )
}
