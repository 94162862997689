import { useGetIntegrationDetails } from "@/api/useIntegrations"
import { useParams } from "react-router-dom"

import { MessageApiProps } from "@/pages/IntegrationsPage/IntegrationsPage.types"
import { ItemIntegrationComponent } from "./ItemIntegrationComponent"

export const ItemIntegrationPage = ({ messageApi }: MessageApiProps) => {
  const { nameIntegration: name } = useParams()
  const {
    response,
    isLoading,
    refetch: refetchIntegration
  } = useGetIntegrationDetails(name!)
  const { data } = response || {}
  const { integration, configuration, extraInfo } = data || {}

  return (
    <ItemIntegrationComponent
      integration={integration}
      configuration={configuration}
      extraInfo={extraInfo}
      isLoading={isLoading}
      messageApi={messageApi}
      refetchIntegration={refetchIntegration}
      name={name}
    />
  )
}
