import * as incidentsApi from "@/api/useIncidents"
import { getIncidents, getIncidentsHistory } from "./useIncidents.select"

import { Params } from "@/hooks/useHttp"

export const useGetIncidents = (params?: Params) => {
  const context = incidentsApi.useGetIncidents(params, {
    select: getIncidents
  })

  return context
}

export const useGetIncidentsHistory = (params?: Params) => {
  const context = incidentsApi.useGetIncidentsHistory(params, {
    select: getIncidentsHistory
  })

  return context
}
