import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Config, Params, useHttp } from "@/hooks/useHttp"
import type {
  IncidentDescribeResponse,
  IncidentsHistoryResponse,
  IncidentsListResponse
} from "./useIncidents.types"

export const useGetIncidents = <T = IncidentsListResponse, S = T>(
  params: Params<{}> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.INCIDENTS.LIST, params, config)

  return context
}

export const useGetIncident = <T = IncidentDescribeResponse, S = T>(
  incidentId: string,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INCIDENTS.DESCRIBE}/${incidentId}`,
    undefined,
    config
  )

  return context
}

export const useGetIncidentsHistory = <T = IncidentsHistoryResponse, S = T>(
  params: Params<{
    daysAgo?: number
    monthsAgo?: number
    yearsAgo?: number
    bucketSizeHours?: number
    firstBucketPercentage?: number
  }> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INCIDENTS.HISTORY,
    params,
    config
  )

  return context
}
