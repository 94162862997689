// import { useGetIncidentsHistory } from "@/api/useIncidents"
import { Card, Flex } from "@/atoms"
import { AlertOutlined, CheckCircleOutlined } from "@/icons"
import { Heading } from "@/molecules/Heading"
import ColumnGraph from "./components/ColumnGraph"
import { StatisticCard } from "./components/StatisticCard"
import { ThreatTab } from "./components/ThreatTab"
// import { Widget } from '@/molecules/Widget'

import { useGetIncidentsHistory } from "@/api/useIncidents"
import { Incident } from "@/api/useIncidents.types"
import styles from "./IncidentsPage.module.scss"

const ContentThreatPage = ({}: { incidents?: Incident[] }) => {
  const { response, isLoading } = useGetIncidentsHistory({
    bucketSizeHours: 6,
    firstBucketPercentage: 5
  })

  const openIncidents = response?.data?.openCount || 0
  const closedIncidents = response?.data?.closedCount || 0

  return (
    <>
      <Flex wrap={false} gap={16} className={styles.container}>
        <Flex vertical gap={16} flex="0 0 144px">
          <StatisticCard
            title="Open"
            value={openIncidents}
            Icon={AlertOutlined}
          />
          <StatisticCard
            title="Closed"
            value={closedIncidents}
            Icon={CheckCircleOutlined}
          />
        </Flex>
        <Flex flex="auto" className={styles.graphContainer}>
          <Card
            title={<Heading title="Incidents In The Past Month" level={6} />}
            className={styles.columnGraphCard}
            loading={isLoading}
          >
            <ColumnGraph dataSource={response?.data?.buckets || []} />
          </Card>
        </Flex>
      </Flex>
      <ThreatTab />
    </>
  )
}

export default ContentThreatPage
