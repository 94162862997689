import cn from "classnames"

import { Button, Divider, Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleOutlined, DeleteOutlined } from "@/icons"
import { Protected } from "@/molecules/Protected"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"
import { FormWebhook } from "../FormWebhook"

import styles from "./ContentItemWebhook.module.scss"

import type { Action, Resource } from "@/domain/user"
import type { ContentItemWebhookProps } from "."

export const ContentItemWebhook = (props: ContentItemWebhookProps) => {
  const formProps = useWebhookAPI(props)
  const { t } = useTranslation()
  // const url = Form.useWatch(formProps.URL_FIELD_NAME, formProps.form)
  const resource = "governance.workflows" as Resource
  const permissions = { resource, action: "write" as Action }

  return (
    <Flex vertical>
      <div className={styles.section}>
        <FormWebhook {...formProps} />
      </div>
      <Divider className={styles.divider} />
      <Flex
        justify="space-between"
        className={cn(styles.section, styles.footer)}
      >
        <Flex gap={8}>
          <Protected shouldDisable permission={permissions}>
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              type="default"
              onClick={formProps.onRemove}
              disabled={formProps.isLoading}
            >
              {t("remove")}
            </Button>
          </Protected>
          {/* <Protected shouldDisable permission={permissions}>
            <Button
              icon={<PlayCircleOutlined />}
              size="small"
              type="default"
              disabled={formProps.isLoading || !url}
              onClick={() => formProps.onTest(url)}
            >
              {t("test")}
            </Button>
          </Protected> */}
        </Flex>
        <Flex gap={8}>
          <Protected shouldDisable permission={permissions}>
            <Button
              size="small"
              type="text"
              disabled={formProps.isLoading || !formProps.isDirty}
              onClick={formProps.onReset}
            >
              {t("general.reset")}
            </Button>
          </Protected>
          <Protected shouldDisable permission={permissions}>
            <Button
              icon={<CheckCircleOutlined />}
              size="small"
              form={formProps.formId}
              htmlType="submit"
              type="primary"
              disabled={!formProps.isDirty}
              loading={formProps.isLoading}
            >
              {t("general.apply")}
            </Button>
          </Protected>
        </Flex>
      </Flex>
    </Flex>
  )
}
